import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DATATABLE_TYPE } from 'state-domains/constants';
import { Field } from 'state-domains/domain';
import {
    buildConfigurationDeleteFieldFromHeaderUrl,
    buildConfigurationDeleteFieldFromSampleHeaderUrl,
} from 'state-domains/network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from 'state-domains/utils';

export const deleteFieldFromHeader = (
    headerId: string,
    sectionId: string,
    fieldId: string,
    configType: DATATABLE_TYPE,
): Observable<string> => {
    const url =
        configType === DATATABLE_TYPE.HEADERS
            ? buildConfigurationDeleteFieldFromHeaderUrl(headerId, sectionId, fieldId)
            : buildConfigurationDeleteFieldFromSampleHeaderUrl(headerId, sectionId, fieldId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const field = convertToCamel<Field>(response);
            return observableOf(field.id);
        }),
    );
};
